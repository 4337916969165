import * as React from 'react';
import nextDynamic, { Loader } from 'next/dynamic';

import { CenteredLoading } from '@zapier/transfer-components';

const dynamic = <P,>(loader: Loader<P>) =>
  nextDynamic(loader, {
    loading: () => <CenteredLoading />,
  });

export default dynamic;
