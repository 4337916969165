import { Zap } from '@zapier/transfer-client';
import isRecurringTransfer from '../../../utils/isRecurringTransfer';
import { relativeUrl, zapierUrl, appendQueryParams } from '../../../utils/urls';

interface MachineContext {
  zapId?: string;
  zap?: Zap;
}

type Action = (context: MachineContext) => void;

// transfer.zapier.com

export const navigateToRecords: Action = ({ zapId }) =>
  window.location.assign(relativeUrl('records', zapId!));

export const navigateToSchedule: Action = ({ zapId }) =>
  window.location.assign(relativeUrl('schedule', zapId!));

export const navigateToChooseTransferType: Action = ({ zapId }) =>
  window.location.assign(relativeUrl('choose', zapId!));

export const navigateToAppPicker = () =>
  window.location.assign(relativeUrl('new'));

export const navigateToAppPickerRecurring = () =>
  window.location.assign(
    appendQueryParams(relativeUrl('new'), { recurring: '1' })
  );

export const navigateToEdit: Action = ({ zapId }) => {
  window.location.assign(relativeUrl('edit', zapId!));
};

// zapier.com

export const navigateToTransfers = () =>
  window.location.assign(zapierUrl('app', 'transfers'));

export const navigateToTransferDetails: Action = ({ zapId }) =>
  window.location.assign(zapierUrl('app', 'transfer', zapId!));

export const navigateToZaps = () =>
  window.location.assign(zapierUrl('app', 'zaps'));

export const navigateToDashboard = () =>
  window.location.assign(zapierUrl('app', 'dashboard'));

export const navigateToPlans = () =>
  window.open(zapierUrl('app', 'billing', 'plans'), '_blank');

export const navigateToEditor: Action = ({ zapId }) =>
  window.location.assign(zapierUrl('app', 'editor', zapId!));

export const navigateToHistory: Action = ({ zapId }) =>
  window.location.assign(zapierUrl('app', 'zap', zapId!, 'history'));

// conditional routing for scheduled or one-time transfers

export const navigateToRecordsByTransferType: Action = (context) =>
  context.zap && isRecurringTransfer(context.zap)
    ? navigateToSchedule(context)
    : navigateToRecords(context);

export const navigateToAppPickerByTransferType: Action = ({ zap }) =>
  zap && isRecurringTransfer(zap)
    ? navigateToAppPickerRecurring()
    : navigateToAppPicker();
