import { actions } from 'xstate';

export type TrackingAction = 'click' | 'connect' | 'enter' | 'error' | 'select';

export type TrackingEvent = {
  type: 'TRACK';
  action: TrackingAction;
  name: string;
  label: string;
  goal: string;
  option: string | null;
  referenceId: string | null;
};

export type SendExprReturningString<Context, Event = any> = (
  context: Context,
  event: Event
) => string;

export type SendExprReturningStringOrNull<Context, Event = any> = (
  context: Context,
  event: Event
) => string | null;

export const track = <Context>(
  action: TrackingAction,
  name: string,
  label: string,
  goal: string | SendExprReturningString<Context> = 'see screen',
  option?: SendExprReturningStringOrNull<Context>,
  referenceId?: SendExprReturningString<Context>
) => {
  return actions.send<Context, any, TrackingEvent>(
    (context: Context, event: any) => ({
      type: 'TRACK',
      action,
      name,
      label,
      goal: typeof goal === 'string' ? goal : goal(context, event),
      option: option !== undefined ? option(context, event) : null,
      referenceId:
        referenceId !== undefined ? referenceId(context, event) : null,
    })
  );
};
