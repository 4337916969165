import { NextRouter, useRouter } from 'next/router';
import { Fragment, FC } from 'react';

type WhenFn = (router: NextRouter) => boolean;

const defaultWhen: WhenFn = ({ query }) => !!Object.keys(query).length;

const RouterReady: FC<{ when?: WhenFn }> = ({
  when = defaultWhen,
  children,
}) => {
  const router = useRouter();
  return <Fragment>{when(router) && children}</Fragment>;
};

export default RouterReady;
