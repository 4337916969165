import { InvokeCreator, State } from 'xstate';
import {
  Context,
  Events,
  Service as AppService,
  StateSchema,
  Typestate,
} from '../../app/types';

const sessionService =
  <TContext>(appService: AppService): InvokeCreator<TContext> =>
  () =>
  (send) => {
    const callback = (
      state?: State<Context, Events, StateSchema, Typestate>
    ) => {
      if (state?.matches('loggedIn')) {
        send({ type: 'LOGGED_IN', data: state?.context.session! });
      }
    };
    appService.onTransition(callback);
    if (appService.initialized) {
      callback(appService.state);
    }
  };

/**
 * Callback service which sends events to the machine that invoked it.
 * Sends a `LOGGED_IN` event when the user has successfully authenticated.
 */
export default sessionService;
