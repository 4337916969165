import { Zap, getZDL, ZDLStep, Need } from '@zapier/transfer-client';

const getZdlStep = (stepIndex: number, zap: Zap) => {
  if (stepIndex === -1) {
    const steps = getZDL(zap).steps;
    return steps[steps.length - 1];
  }
  return getZDL(zap).steps[stepIndex];
};

/** Returns the trigger (read) step of a two-step zap. */
export const getReadStep = getZdlStep.bind(this, 0);

/** Returns the action (write) step of a two-step zap. */
export const getWriteStep = getZdlStep.bind(this, -1);

export const getStepTransferMeta = (step: ZDLStep) => {
  step.meta = step.meta || {};
  step.meta.transfer = step.meta.transfer || {};
  return step.meta.transfer;
};

export const getReadStepTransferMeta = (zap: Zap) =>
  getStepTransferMeta(getReadStep(zap));

export const getWriteStepTransferMeta = (zap: Zap) =>
  getStepTransferMeta(getWriteStep(zap));

export const getStepParamLabel = (step: ZDLStep, key: Need['key']) => {
  const labels = getStepTransferMeta(step).labels || {};
  return labels[key];
};
